import React from 'react';
import Layout from '../components/layout';
import { Link } from 'gatsby';

import { MainWrapper, Title } from '../components/headings';

const Projects = ({ location }) => (
	<Layout location={location}>
		<MainWrapper color="#845fea">
			<Title>Projects</Title>
			{/* <Link to="/">This is a link</Link> */}
			<p>If your intrested in my work please reach out and I would be happy to share some of my projects.</p>
		</MainWrapper>
	</Layout>
);

export default Projects;
